import {
    BrowserRouter,
    Switch,
    Route
} from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import NotFoundPage from './pages/NotFoundPage';


function AppRouter() {

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" component={LandingPage} exact={true} />
                <Route component={NotFoundPage} />
            </Switch>
        </BrowserRouter>
    );
}

export default AppRouter;